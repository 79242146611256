import PortableTextSection from "@/components/portableTextSection/PortableTextSection";
import { portableTextSectionComponents } from "@/components/portableTextSection/portableText/PortableText";
import { featuredArtists } from "@/lib/transforms/program/artists";
import { ProgramPhase } from "@/sanity/schemas/vendored/enums";
import EventMedia from "../eventMedia/EventMedia";

import styles from "./FeaturedArtist.module.scss";
import portableTextStyles from "./PortableText.module.scss";

export interface FeaturedArtistProps {
	artist: ReturnType<typeof featuredArtists>[0];
	currentPhase: ProgramPhase;
}

export default function FeaturedArtist({
	artist,
	currentPhase,
}: FeaturedArtistProps) {
	const { image, title, copy } = artist;

	const artistTitle = (
		<PortableTextSection
			value={title}
			components={portableTextSectionComponents(currentPhase, styles)}
		/>
	);
	const artistCopy = copy ? (
		<PortableTextSection
			value={copy}
			components={portableTextSectionComponents(
				currentPhase,
				portableTextStyles,
			)}
		/>
	) : null;
	const artistImage = <EventMedia image={image} size="feature" />;
	return (
		<div className={styles.container}>
			{artistImage}
			<div className={styles.artists}>{artistTitle}</div>
			{artistCopy}
		</div>
	);
}
