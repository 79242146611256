import { createClient } from "next-sanity";

import { clientEnv } from "./env.client.mjs";

/**
 * Create a Sanity client to fetch content in client-side contexts like React
 * components or front-end code.
 *
 * @param overrideUseCdn – Set a boolean value to focibly override option to
 * fetch content from the Sanity CDN versus the slower but fresher direct API.
 * Defaults to value of `NEXT_PUBLIC_SANITY_USE_CDN`. Production should use
 * `true` but doing so prevents fetching of draft content.
 * @param preview container with `{ token: string }` to enable preview/draft
 * mode.
 *
 * Based on
 * https://github.com/sanity-io/next-sanity/blob/HEAD/PREVIEW-app-router.md
 */
export function createClientSideSanityClient({
	preview,
	overrideUseCdn = undefined,
}: { preview?: { token: string }; overrideUseCdn?: boolean } = {}) {
	const client = createClient({
		projectId: clientEnv.NEXT_PUBLIC_SANITY_PROJECT_ID,
		dataset: clientEnv.NEXT_PUBLIC_SANITY_DATASET,
		apiVersion: clientEnv.NEXT_PUBLIC_SANITY_API_VERSION,

		perspective: clientEnv.NEXT_PUBLIC_ENABLE_SANITY_CLIENT_SIDE_DRAFTS
			? "previewDrafts"
			: "published",

		useCdn:
			overrideUseCdn !== undefined
				? overrideUseCdn
				: clientEnv.NEXT_PUBLIC_SANITY_USE_CDN,

		// Permit users logged in to Sanity Studio to preview draft content by
		// passing their credentials along with this client's requests
		withCredentials: clientEnv.NEXT_PUBLIC_ENABLE_SANITY_CLIENT_SIDE_DRAFTS,
	});

	if (preview) {
		if (!preview.token) {
			throw new Error("You must provide a token to preview drafts");
		}
		return client.withConfig({
			token: preview.token,
			useCdn: false,
			ignoreBrowserTokenWarning: true,
			perspective: "previewDrafts",
		});
	}

	return client;
}

// Sanity client used soley for fetching images, should always use the CDN
export const imagesSanityClient = createClientSideSanityClient({
	overrideUseCdn: true,
});
