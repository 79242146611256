import { ProgramPhase } from "@/sanity/schemas/vendored/enums";
import { PortableTextComponents } from "@portabletext/react";
import BulletList from "./elements/BulletList";
import BlockButton from "./elements/Button";
import BlockDoubleButton from "./elements/DoubleButton";
import BlockFeaturedArtist from "./elements/FeaturedArtist";
import FinePrint from "./elements/FinePrint";
import FinePrintTitle from "./elements/FinePrintTitle";
import BlockImageGrid from "./elements/ImageGrid";
import InternalLink from "./elements/InternalLink";
import ListItem from "./elements/ListItem";
import Normal from "./elements/Normal";
import { PhaseSpecificCopy } from "./elements/PhaseSpecificCopy";
import SmartLink from "./elements/SmartLink";
import Strong from "./elements/Strong";
import Subtitle from "./elements/Subtitle";
import Title from "./elements/Title";
import BlockVideo from "./elements/Video";
import BlockImage from "./elements/_Image";

type PortableTextClasses =
	| "externalLink"
	| "internalLink"
	| "normal"
	| "title"
	| "subtitle"
	| "finePrintTitle"
	| "finePrint"
	| "button"
	| "bulletList"
	| "listItem"
	| "normalBlack"
	| "strong"
	| "video"
	| "image"
	| "imageGrid"
	| "featured"
	| "doubleButtons";

export default function createBasicPortableTextComponents(
	{
		externalLink,
		internalLink,
		normal,
		title,
		subtitle,
		finePrintTitle,
		finePrint,
		button,
		bulletList,
		listItem,
		strong,
		video,
		image,
		imageGrid,
		featured,
		doubleButtons,
	}: Partial<Record<PortableTextClasses, string>>,
	currentPhase: ProgramPhase,
): PortableTextComponents {
	return {
		marks: {
			externalLink: (props) => (
				<SmartLink className={externalLink} {...props} />
			),
			internalLink: (props) => (
				<InternalLink className={internalLink} {...props} />
			),
			strong: (props) => <Strong className={strong} {...props} />,
			preOnSale: (props) => (
				<PhaseSpecificCopy
					phase={ProgramPhase.ProgramAnnounce}
					currentPhase={currentPhase}
					{...props}
					onlyThisPhase
				/>
			),
			postOnSale: (props) => (
				<PhaseSpecificCopy
					phase={ProgramPhase.GeneralOnsale}
					currentPhase={currentPhase}
					{...props}
					onlyThisPhase={false}
				/>
			),
		},
		block: {
			normal: (props) => <Normal className={normal} {...props} />,
			title: (props) => <Title className={title} {...props} />,
			subtitle: (props) => <Subtitle className={subtitle} {...props} />,
			finePrintTitle: (props) => (
				<FinePrintTitle className={finePrintTitle} {...props} />
			),
			finePrint: (props) => <FinePrint className={finePrint} {...props} />,
		},
		types: {
			button: (props) => <BlockButton className={button} {...props} />,
			blockVideo: (props) => <BlockVideo className={video} {...props} />,
			blockImage: (props) => <BlockImage className={image} {...props} />,
			blockImageGrid: (props) => (
				<BlockImageGrid className={imageGrid} {...props} />
			),
			artistRef: (props) => (
				<BlockFeaturedArtist className={featured} {...props} />
			),
			doubleButton: (props) => (
				<BlockDoubleButton className={doubleButtons} {...props} />
			),
		},
		list: {
			bullet: (props) => <BulletList className={bulletList} {...props} />,
		},
		listItem: {
			bullet: (props) => <ListItem className={listItem} {...props} />,
			number: (props) => <ListItem className={listItem} {...props} />,
		},
	};
}
