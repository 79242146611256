import classNames from 'classnames';
import React from 'react';
import styles from '../PortableText.module.scss';

interface FinePrintProps {
  children?: React.ReactNode;
  className?: string;
}

/**
 * Inject
 * @param props
 * @returns
 */
export default function FinePrint({
  children,
  className,
}: FinePrintProps) {
  if (!children) {
    return null;
  }

  return (
    <h4 className={classNames(styles.finePrint, className)}>
      {children}
    </h4>
  );
}
