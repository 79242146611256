import classNames from 'classnames';
import React from 'react';
import styles from '../PortableText.module.scss';

interface NormalProps {
  children?: React.ReactNode;
  className?: string;
}

/**
 * Inject
 * @param props
 * @returns
 */
export default function Normal({
  children,
  className = styles.normal,
}: NormalProps) {
  if (!children) {
    return null;
  }

  return (
    <p className={className}>
      {children}
    </p>
  );
}
