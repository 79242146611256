import FeaturedArtist from "@/components/program/event/featuredArtist/FeaturedArtist";
import { imageUrlWithSize } from "@/lib/sanity/helpers/image";
import { ProgramPhase } from "@/sanity/schemas/vendored/enums";
import { PortableTextTypeComponentProps } from "@portabletext/react";
import classNames from "classnames";
import styles from "../PortableText.module.scss";

export interface BlockFeaturedArtistProps
	extends PortableTextTypeComponentProps<any> {
	className?: string;
}

/**
 * Inject
 * @param props
 * @returns
 */
export default function BlockFeaturedArtist({
	value,
	className,
}: BlockFeaturedArtistProps) {
	if (!value) {
		return null;
	}

	const { image, copy, title } = value;

	const imageUrl = imageUrlWithSize(image.image.asset._ref, 2400, 1800);

	const artist = {
		image: {
			url: imageUrl,
			alt: image.alt,
		},
		title,
		copy,
	};

	return (
		<div className={classNames(styles.featuredArtist, className)}>
			<FeaturedArtist artist={artist} currentPhase={ProgramPhase.WinterFeast} />
		</div>
	);
}
