import classNames from 'classnames';
import React from 'react';
import styles from '../PortableText.module.scss';
import { idFromHeading } from './AnchorLinks';

interface TitleProps {
  children?: React.ReactNode;
  className?: string;
}

/**
 * Inject
 * @param props
 * @returns
 */
export default function Title({
  children,
  className,
}: TitleProps) {
  if (!children) {
    return null;
  }

  const id = idFromHeading(children);

  return (
    <h2 className={classNames(styles.title, className)} id={id}>
      {children}
    </h2>
  );
}
