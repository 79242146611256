import type {
  PortableTextTypeComponentProps,
} from '@portabletext/react';
import classNames from 'classnames';
import Link from 'next/link';
import styles from '../PortableText.module.scss';

export interface BlockButtonProps extends PortableTextTypeComponentProps<any> {
  className?: string;
};

export interface ButtonProps {
  url: string;
  text: string;
  className?: string;
};

/**
 * Inject
 * @param props
 * @returns
 */
export default function BlockButton({
  value,
  isInline,
  className,
}: BlockButtonProps) {
  if (!value) {
    return null;
  }

  const {
    text,
    url,
  } = value;

  if (!url) {
    return null;
  }

  return <Button url={url} text={text} className={className}/>
}

export function Button({
  url,
  text,
  className,
}: ButtonProps) {
  if (url.indexOf('/') === 0) {
    return (
      <Link href={url} className={classNames(styles.blockButton, className)}>
        {text}
      </Link>
    );
  }

  return (
    <a href={url} target="_blank" rel="noreferrer" className={styles.blockButton}>
      {text}
    </a>
  );
}