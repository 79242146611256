import Image from "next/image";

import { isTruthy } from "@/lib/utils";

import { imageUrlWithSize } from "@/lib/sanity/helpers/image";
import type { PortableTextTypeComponentProps } from "@portabletext/react";
import classNames from "classnames";
import styles from "../PortableText.module.scss";

import { nextImageMediaSize } from "@/lib/helpers/imageSizes";
import BlockImageGridLightbox from "./ImageGridLightbox";

export interface BlockImageGridProps
	extends PortableTextTypeComponentProps<any> {
	className?: string;
}

/**
 * Inject
 * @param props
 * @returns
 */
export default function BlockImageGrid({
	value,
	className,
}: BlockImageGridProps) {
	if (!value) {
		return null;
	}

	const { enableLightbox, partnerLogos } = value;

	const lightboxEnabled = enableLightbox === true;

	return (
		<>
			{lightboxEnabled ? (
				<>
					{isTruthy(value.images) && (
						<BlockImageGridLightbox
							images={value.images}
							className={classNames(styles.blockImageGrid, className)}
						/>
					)}
				</>
			) : (
				<>
					{isTruthy(value.images) && (
						<ul className={classNames(styles.blockImageGrid, className)}>
							{value.images.map((image) => {
								const imageAsset = image.image.asset;

								const imageUrl = partnerLogos
									? imageUrlWithSize(imageAsset._ref, 640, 480)
									: imageUrlWithSize(imageAsset._ref, 1000, 1000);

								const asset = {
									url: imageUrl,
									alt: image.alt,
								};

								return (
									<li key={image._key} className={styles.blockImageGridItem}>
										<Image
											key={image._key}
											className={styles.blockImageAsset}
											src={asset.url}
											alt={asset.alt}
											{...nextImageMediaSize("listing")}
										/>
									</li>
								);
							})}
						</ul>
					)}
				</>
			)}
		</>
	);
}
