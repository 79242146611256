import type { PortableTextMarkComponentProps } from "@portabletext/react";
import classNames from "classnames";
import Link from "next/link";
import styles from "../PortableText.module.scss";
import { createUrl } from "../helpers/CreateUrl";

export interface ScrollAnchorProps extends PortableTextMarkComponentProps {
	className?: string;
}

/**
 * Inject
 * @param props
 * @returns
 */
export default function SmartLink({
	children,
	value,
	className,
}: ScrollAnchorProps) {
	if (!children || !value) {
		return null;
	}

	const { url, target, specialType } = value;

	const specialUrl = createUrl(url, specialType);

	if (!url) {
		return null;
	}

	if (url.indexOf("/") === 0) {
		return (
			<Link href={url} className={classNames(styles.smartLink, className)}>
				{children}
			</Link>
		);
	}

	return (
		<Link
			href={specialUrl}
			target={target === "_blank" ? "_blank" : "_self"}
			rel={target ? "noreferrer" : undefined}
			className={classNames(styles.smartLink, className)}
		>
			{children}
		</Link>
	);
}
