import { imagesSanityClient } from "@/lib/clientSide/sanity";
import type { Dimensions } from "@/types/media";
import imageUrlBuilder from "@sanity/image-url";
import type { SanityImageSource } from "@sanity/image-url/lib/types/types";

const imageBuilder = imageUrlBuilder(imagesSanityClient);

export function imageUrlWithSize(
	source: string,
	width: number,
	height: number,
): string {
	return imageBuilder
		.image(source)
		.width(width)
		.height(height)
		.fit("max")
		.url();
}

export function imageCrop(
	image: SanityImageSource,
	{ width, height }: Dimensions,
): string {
	return imageBuilder.image(image).width(width).height(height).fit("max").url();
}
