"use client";

import MuxPlayer from "@mux/mux-player-react";
import "@mux/mux-player/themes/microvideo";
import classNames from "classnames";
import styles from "./InlineVideo.module.scss";

export interface InlineVideoProps {
	autoplay?: boolean;
	className?: string;
	playbackId: string;
	hls?: boolean;
	widescreen?: boolean;
}

export default function InlineVideo({
	autoplay,
	className,
	playbackId,
	hls,
	widescreen,
}: InlineVideoProps) {
	return (
		<MuxPlayer
			loop
			nohotkeys
			autoPlay={autoplay ? "auto" : false}
			muted={autoplay}
			className={classNames(
				widescreen ? styles.muxPlayerWide : styles.muxPlayer,
				className,
			)}
			key={playbackId}
			src={hls ? playbackId : undefined}
			playbackId={hls ? undefined : playbackId}
			streamType={hls ? "ll-live" : "on-demand"}
			theme="microvideo"
			primaryColor="var(--background-secondary-black)"
			secondaryColor="var(--foreground-primary-yellow)"
			preload="auto"
			playsInline={false}
		/>
	);
}
