import type {
  PortableTextTypeComponentProps,
} from '@portabletext/react';
import classNames from 'classnames';
import styles from '../PortableText.module.scss';
import { Button } from './Button';

export interface BlockDoubleButtonProps extends PortableTextTypeComponentProps<any> {
  className?: string;
};

/**
 * Inject
 * @param props
 * @returns
 */
export default function BlockDoubleButton({
  value,
  className,
}: BlockDoubleButtonProps) {
  if (!value) {
    return null;
  }

  const {
    button1,
    button2,
  } = value;

  if (!button1 && ! button2) {
    return null;
  }

  const firstButton = <Button url={button1.url} text={button1.text} className={styles.halfWidthButton} />
  const secondButton = <Button url={button2.url} text={button2.text} className={styles.halfWidthButton} />

  return (
    <div className={classNames(className, styles.blockDoubleButtons)}>
      {firstButton}
      {secondButton}
    </div>
  );
}