import classNames from 'classnames';
import React from 'react';
import styles from '../PortableText.module.scss';

interface StrongProps {
  children?: React.ReactNode;
  className?: string;
}

/**
 * Inject
 * @param props
 * @returns
 */
export default function Strong({
  children,
  className,
}: StrongProps) {
  if (!children) {
    return null;
  }

  return (
    <span className={classNames(styles.strong, className)}>
      {children}
    </span>
  );
}
