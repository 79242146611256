import { ProgramPhase } from "@/sanity/schemas/vendored/enums";
import { PortableText, PortableTextReactComponents } from "@portabletext/react";
import classNames from "classnames";
import ElementByType, { ElementType } from "../utils/ElementByType";
import styles from "./PortableTextSection.module.scss";
import { portableTextSectionComponents } from "./portableText/PortableText";

export interface PortableTextSectionProps {
	value: any;
	className?: string;
	components?: Partial<PortableTextReactComponents>;
	wrapper?: ElementType;
}

export default function PortableTextSection({
	value,
	className,
	wrapper = "section",
	components = portableTextSectionComponents(ProgramPhase.GeneralOnsale),
}: PortableTextSectionProps) {
	return (
		<ElementByType
			className={classNames(styles.container, className)}
			type={wrapper}
		>
			<PortableText value={value} components={components} />
		</ElementByType>
	);
}
