type ObjectKey = string | number | symbol;

/**
 * Return whether the given value is **truthy** following Python's truthiness
 * rules, which are *better* (more usable, less surprising) than Javascript's.
 *
 * Differences from Javascript's truthiness rules:
 * - Empty array is `false`
 * - Empty Set is `false`
 * - Empty object is `false`
 *
 * See:
 * - https://developer.mozilla.org/en-US/docs/Glossary/Truthy
 * - https://stackoverflow.com/a/39984051/4970
 *
 * We use a type predicate so Typescript treats this function like a standard
 * inline boolean check:
 * https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
 */
export function isTruthy(value: unknown): value is boolean {
	if (value === 0) {
		return false;
	}

	if (Array.isArray(value)) {
		return value.length > 0;
	}
	if (value instanceof Set) {
		return value.size > 0;
	}

	// An empty object is falsy, but detecting an empty JS object is surprisingly
	// difficult. This particular approach is based on the fastest method tested
	// in https://stackoverflow.com/a/59787784/4970
	if (typeof value === "object") {
		let isEmpty = true;
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		for (const _ in value) {
			isEmpty = false;
			break;
		}
		return !isEmpty;
	}

	return Boolean(value);
}

/**
 * Return whether the given value is **falsey** following Python's truthiness
 * rules, which are *better* (more usable, less surprising) than Javascript's.
 * This is the inverse of `isTruthy()`.
 */
export function isFalsey(value: unknown): boolean {
	return !isTruthy(value);
}

/**
 * Return whether a given environment variable value should evaluate to true or
 * truthy, false otherwise.
 *
 * True values are: `1`, `true`, `yes`, `on`
 *
 * Everything else evaluates to `false`, including: unset, `0`, `false`, `no`,
 * `off`
 */
export function isTruthyEnvVar(value: string): boolean {
	return ["1", "true", "yes", "on"].includes(value?.toLowerCase());
}

/**
 * Return true if a given argument is non-nullish (not null or undefined) with
 * a type predicate to force Typescript to treat the value as non-nullish in
 * following code.
 *
 * This is very useful for filter then map over potentially nullish values:
 *
 *   arrayWithNullishValues.filter(isNotNullish).map(nonNullishValue => ...)
 *
 * Based on https://stackoverflow.com/a/46700791
 */
export function isNotNullish<T>(value: T | null | undefined): value is T {
	if (value === null || value === undefined) return false;

	// Dummy variable to trigger Typescript type narrowing
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const testDummy: T = value;

	return true;
}

/**
 * Generate a range of integer values between a start value (inclusive) and a
 * stop value (exclusive) with an optional step size (default 1), to match the
 * implementation of Python's built-in `range()` function.
 *
 * Implemented as a generator function to be efficiency for very large stop
 * values. However the result will need to be converted to a standard array to
 * use with `filter()` or `map()` functions, e.g. `[...range(6)]` or
 * `Array.from(range(6))`.
 *
 * Example usage:
 *
 *     range(6) # 0, 1, 2, 3, 4, 5
 *
 *     range(1, 6) # 1, 2, 3, 4, 5
 *
 *     range(6, 6) # <nothing>
 *
 *     range(1, 6, 2) # 1, 3, 5
 *
 *     range(1, 6, 5) # 1
 *
 *     range(6, 0, -1) # 6, 5, 4, 3, 2, 1
 *
 *
 * See
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Iterators_and_Generators#generator_functions
 *
 * @param {*} start Start offset for the range, or if `stop` is not provided
 * will act as the stop offset for a range with 0 as `start`.
 * @param {*} stop Stop offset for the range, is *excluded* from the result.
 * @param {*} step Step size for the range, default is 1. Negative step values
 * are supported provided `start` is greater than `stop`. A zero step value
 * returns nothing.
 * @returns
 */
export function* range(start: number, stop?: number, step = 1) {
	if (step === 0) {
		return;
	}

	if (stop === undefined) {
		stop = start;
		start = 0;
	}

	let current = start;
	while (step > 0 ? current < stop : current > stop) {
		yield current;

		current += step;
	}
}

/**
 * Return an object's value matching the given case-insensitive key.
 *
 * @param {*} obj
 * @param {*} key
 * @returns
 */
export function getByKeyIgnoreCase<T>(
	obj: Record<string, T>,
	key: string,
): T | undefined {
	const matchingKey = Object.keys(obj || {}).find(
		(k) => k.toLowerCase() === key.toLowerCase(),
	);
	if (matchingKey) {
		return obj[matchingKey];
	}
}

/**
 * Convert an array of objects into a single object with a key for every key
 * found in an item, mapped to a list of values for that key's values across
 * all items.
 *
 * Example:
 *
 *   const arr = [
 *     { id: 1, name: 'foo' },
 *     { id: 1, name: 'bar' },
 *     { id: 2, name: 'baz' }
 *   ]
 *
 *   flattenKeysInObjects(arr)
 *   {
 *     'id': [
 *        [1, 1, 2]
 *      ],
 *      'name': [
 *        'foo', 'bar', 'baz'
 *      ]
 *   }
 */
export function flattenKeysInObjects<T>(
	arr: Array<Record<string, T>>,
): Record<string, T> {
	const flatResults = {};
	arr
		.filter((item) => item) // Ignore empty items
		.forEach((item) => {
			Object.keys(item).forEach((itemKey) => {
				if (!flatResults[itemKey]) {
					flatResults[itemKey] = [];
				}
				flatResults[itemKey].push(item[itemKey]);
			});
		});
	return flatResults;
}

/**
 * Base implementation for `arrayToObjectWithFieldName` and
 * arrayToListsObjectWithFieldName`
 */
function baseArrayToObjectWithFieldName<T extends object | undefined>({
	arr,
	fieldName,
	raise = true,
	makeList = false,
}: {
	arr: Array<T>;
	fieldName: string;
	raise?: boolean;
	makeList?: boolean;
}): Record<string, T> | Record<string, Array<T>> {
	const obj = {};

	if (!arr || arr.length === 0) {
		return obj;
	}

	arr.forEach((item) => {
		if (
			!item ||
			(!Object.prototype.hasOwnProperty.call(item, fieldName) && raise)
		) {
			throw new Error(`No value for field '${fieldName}' in item: ${item}`);
		}

		const keyValue = item[fieldName];

		if (makeList) {
			if (!obj[keyValue]) {
				obj[keyValue] = [];
			}
			obj[keyValue].push(item);
		} else {
			obj[keyValue] = item;
		}
	});
	return obj;
}

/**
 * Convert an array of objects to a single object that includes each item in
 * the array as a key/value pair, with the key derived from the value of the
 * given field name.
 *
 * Examples:
 *
 *   const arr = [
 *     { id: 1, val: 'foo' },
 *     { id: 2, val: 'bar' }
 *     { id: 3, val: 'bar' }
 *   ]
 *
 *   arrayToObjectWithFieldName(arr, 'id')
 *   {
 *     1: { id: 1, val: 'foo' },
 *     2: { id: 2, val: 'bar' }
 *   }
 *
 *   arrayToObjectWithFieldName(arr, 'name')
 *   {
 *     foo: { id: 1, val: 'foo' },
 *     bar: { id: 2, val: 'bar' }
 *   }
 *
 * @param {*} arr Array of objects
 * @param {*} fieldName Name of field to use as key values
 * @param {*} raise If true, throw an error if a key is missing. Default: true
 */
export function arrayToObjectWithFieldName<T extends object | undefined>(
	arr: Array<T>,
	fieldName: string,
	raise = true,
): Record<string, T> {
	return baseArrayToObjectWithFieldName<T>({
		arr,
		fieldName,
		raise,
		makeList: false,
	}) as Record<string, T>;
}

/**
 * Convert an array of objects to a single object that includes each item in
 * the array as a key/values pair, with the key derived from the value of the
 * given field name and the values list populated with all the items with the
 * same key.
 *
 * Examples:
 *
 *   const arr = [
 *     { id: 1, val: 'foo' },
 *     { id: 1, val: 'bar' },
 *     { id: 1, val: 'baz' }
 *   ]
 *
 *   arrayToListsObjectWithFieldName(arr, 'id')
 *   {
 *     1: [{ id: 1, val: 'foo' }, { id: 1, val: 'bar' }],
 *     2: [{ id: 2, val: 'baz' }]
 *   }
 *
 *   arrayToListsObjectWithFieldName(arr, 'val')
 *   {
 *     'foo': [{ id: 1, val: 'foo' }],
 *     'bar': [{ id: 1, val: 'bar' }],
 *     'baz': [{ id: 2, val: 'baz' }]
 *   }
 *
 * @param {*} arr Array of objects
 * @param {*} fieldName Name of field to use as key values
 * @param {*} raise If true, throw an error if a key is missing. Default: true
 */
export function arrayToListsObjectWithFieldName<T extends object | undefined>(
	arr: Array<T>,
	fieldName: string,
	raise = true,
): Record<string, Array<T>> {
	return baseArrayToObjectWithFieldName<T>({
		arr,
		fieldName,
		raise,
		makeList: true,
	}) as Record<string, Array<T>>;
}

/**
 * Base implementation for `arrayToObjectWithFunction` and
 * `arrayToListsObjectWithFunction`
 */
function baseArrayToObjectWithFunction<T extends object | undefined>({
	arr,
	fn,
	raise = true,
	makeList = false,
}: {
	arr: Array<T>;
	fn: (item: T) => ObjectKey | Array<ObjectKey> | undefined;
	raise: boolean;
	makeList: boolean;
}): Record<string, T> | Record<string, Array<T>> {
	const obj = {};

	if (!arr || arr.length === 0) {
		return obj;
	}

	arr.forEach((item) => {
		const keyValueOrValues = fn(item);

		// Raise or skip on invalid key value(s), i.e. anything that doesn't have a
		// length (empty array, null, undefined, etc.)
		if (isFalsey(keyValueOrValues) && raise) {
			throw new Error(`No key value from function ${fn} in item: ${item}`);
		} else {
			let innerObj = obj;
			let currentKeyValue: ObjectKey | undefined;

			// If key function returned an array, prepare to nest the object
			if (Array.isArray(keyValueOrValues)) {
				while (keyValueOrValues.length > 1) {
					currentKeyValue = keyValueOrValues.shift();

					if (currentKeyValue) {
						if (!innerObj[currentKeyValue]) {
							innerObj[currentKeyValue] = {};
						}

						innerObj = innerObj[currentKeyValue];
					}
				}

				currentKeyValue = keyValueOrValues.shift();
			} else {
				currentKeyValue = keyValueOrValues;
			}

			if (makeList && currentKeyValue) {
				if (!innerObj[currentKeyValue]) {
					innerObj[currentKeyValue] = [];
				}
				innerObj[currentKeyValue].push(item);
			} else if (currentKeyValue) {
				innerObj[currentKeyValue] = item;
			}
		}
	});
	return obj;
}

/**
 * Convert an array of objects to a single object that includes each item as a
 * key/value pair, with the key(s) derived by running the given function on an
 * array item.
 *
 * The key name function can return an array of values, in which case the item
 * is given nested keys in the resulting object.
 *
 * Examples:
 *
 *   const arr = [
 *     { id: 1, name: 'foo' },
 *     { id: 2, name: 'bar' }
 *   ]
 *
 *   arrayToObjectWithFunction(arr, (item) => item.id)
 *   {
 *     1: { id: 1, name: 'foo' },
 *     2: { id: 2, name: 'bar' }
 *   }
 *
 *   arrayToObjectWithFunction(arr, (item) => [item.id, item.name])
 *   {
 *     1: { 'foo': { id: 1, name: 'foo' }},
 *     2: { 'bar': { id: 2, name: 'bar' }},
 *   }
 *
 * @param {*} arr Array of objects
 * @param {*} fn Function that takes a single array item and returns either a
 * key name, or an array of key names, to assign the item in the result object
 * @param {*} raise If true, throw an error if function fails to return key
 * values for a an item. Default: true
 */
export function arrayToObjectWithFunction<T extends object | undefined>(
	arr: Array<T>,
	fn: (item: T) => ObjectKey | Array<ObjectKey> | undefined,
	raise = true,
): Record<string, T> {
	return baseArrayToObjectWithFunction<T>({
		arr,
		fn,
		raise,
		makeList: false,
	}) as Record<string, T>;
}

/**
 * Convert an array of objects to a single object that includes each item as a
 * key/values pair, with the key(s) derived by running the given function on an
 * array item and the values list populated with all the items with the same
 * derived key.
 *
 * The key name function can return an array of values, in which case the item
 * is given nested keys in the resulting object.
 *
 * Examples:
 *
 *   const arr = [
 *     { id: 1, name: 'foo' },
 *     { id: 1, name: 'bar' }
 *     { id: 2, name: 'baz' }
 *   ]
 *
 *   arrayToListsObjectWithFunction(arr, (item) => item.id)
 *   {
 *     1: [{ id: 1, name: 'foo' }, { id: 1, name: 'bar' } ],
 *     2: [{ id: 2, name: 'baz' } ]
 *   }
 *
 *   arrayToListsObjectWithFunction(arr, (item) => [item.id, item.name])
 *   {
 *     1: [{ 'foo': { id: 1, name: 'foo' }}, { 'bar': { id: 1, name: 'bar' }}],
 *     2: { 'baz': { id: 2, name: 'baz' }},
 *   }
 *
 * @param {*} arr Array of objects
 * @param {*} fn Function that takes a single array item and returns either a
 * key name, or an array of key names, to assign the item in the result object
 * @param {*} raise If true, throw an error if function fails to return key
 * values for a an item. Default: true
 */
export function arrayToListsObjectWithFunction<T extends object | undefined>(
	arr: Array<T>,
	fn: (item: T) => ObjectKey | Array<ObjectKey> | undefined,
	raise = true,
): Record<string, Array<T>> {
	return baseArrayToObjectWithFunction<T>({
		arr,
		fn,
		raise,
		makeList: true,
	}) as Record<string, Array<T>>;
}

/**
 * Copy the given text to the user's clipboard.
 *
 * https://blog.logrocket.com/implementing-copy-clipboard-react-clipboard-api/
 */
export async function copyTextToClipboard(text: string) {
	if ("clipboard" in navigator) {
		return await navigator.clipboard.writeText(text);
	}
	// Support ancient browsers
	else {
		return document.execCommand("copy", true, text);
	}
}
