export function createUrl(url: string, specialType: string | null) {
  if(!specialType) {
    return url;
  }

  if(specialType === 'email') {
    return `mailto:${url}`;
  }

  if(specialType === 'phone') {
    return `tel:${url}`;
  }

  return url;
}