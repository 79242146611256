import createBasicPortableTextComponents from "@/components/portableText/createBasicPortableText";
import { ProgramPhase } from "@/sanity/schemas/vendored/enums";
import { PortableTextComponents } from "@portabletext/react";
import styles from "./PortableText.module.scss";

export type BodyColorVariant = "tertiaryPink" | "primaryYellow";

export function portableTextSectionComponents(
	phase: ProgramPhase,
	style = styles,
): PortableTextComponents {
	return createBasicPortableTextComponents(style, phase);
}
