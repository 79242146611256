import InlineVideo from "@/components/media/inlineVideo/InlineVideo";
import type { PortableTextTypeComponentProps } from "@portabletext/react";
import classNames from "classnames";
import styles from "../PortableText.module.scss";

export interface BlockVideoProps extends PortableTextTypeComponentProps<any> {
	className?: string;
}

/**
 * Inject
 * @param props
 * @returns
 */
export default function BlockVideo({ value, className }: BlockVideoProps) {
	if (!value) {
		return null;
	}

	const { caption, videoId } = value;

	if (!videoId) {
		return null;
	}

	return (
		<div className={classNames(styles.blockVideo, className)}>
			<InlineVideo playbackId={videoId} />
			<p className={styles.blockVideoCaption}>{caption}</p>
		</div>
	);
}
