"use client";

import { useState } from "react";

import Image from "next/image";

import { nextImageMediaSize } from "@/lib/helpers/imageSizes";
import { imageUrlWithSize } from "@/lib/sanity/helpers/image";
import styles from "../PortableText.module.scss";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

interface ImageObject {
	_key: string;
	image: {
		asset: {
			url: string;
		};
	};
	alt: string;
}

export default function BlockImageGridLightbox({
	images,
	className,
}: {
	images?: Array<ImageObject>;
	className?: string;
}) {
	const [slideIndex, setSlideIndex] = useState<number>(-1);

	const imageSlides = images?.map((image) => {
		const imageAsset = image.image.asset;

		const imageUrl = imageUrlWithSize(imageAsset.url, 2400, 1800);

		return {
			src: imageUrl,
			alt: image.alt,
		};
	});

	const handleImageClick = (index: number) => {
		setSlideIndex(index);
	};

	return (
		<>
			<ul className={className}>
				{imageSlides?.map((image, idx) => {
					return (
						<li key={idx} className={styles.blockImageGridItemLightbox}>
							<Image
								className={styles.blockImageAsset}
								src={image.src}
								alt={image.alt}
								onClick={() => handleImageClick(idx)}
								{...nextImageMediaSize("listing")}
							/>
						</li>
					);
				})}
			</ul>
			{/* https://yet-another-react-lightbox.com/documentation */}
			<Lightbox
				open={slideIndex >= 0}
				close={() => setSlideIndex(-1)}
				className={className}
				slides={imageSlides}
				// https://yet-another-react-lightbox.com/customization
				styles={{ root: { "--yarl__color_backdrop": "rgba(0, 0, 0, 0.8)" } }}
			/>
		</>
	);
}
