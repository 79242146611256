import classNames from 'classnames';
import React from 'react';
import styles from '../PortableText.module.scss';

interface BulletListProps {
  children?: React.ReactNode;
  className?: string;
}

export default function BulletList({
  children,
  className,
}: BulletListProps) {
  if (!children) {
    return null;
  }

  return (
    <ul className={classNames(styles.bulletList, className)}>
      {children}
    </ul>
  );
}
