import { PortableTextMarkComponentProps } from "@portabletext/react";
import Link from "next/link";
import styles from "../PortableText.module.scss";

export interface InternalLinkProps extends PortableTextMarkComponentProps {
	className?: string;
}

/**
 * Inject
 * @param props
 * @returns
 */
export default function InternalLink({
	children,
	className = styles.internalLink,
	value,
}: InternalLinkProps) {
	if (!children) {
		return null;
	}

	const { reference, type } = value ?? { reference: null, type: null };

	// Render just emphasised text if we cannot generate a valid link
	if (!reference || !type) {
		return <em>{children}</em>;
	}

	const href = `${getTypeSlug(type)}/${reference?.slug?.current}`;

	return (
		<Link href={href} className={className}>
			{children}
		</Link>
	);
}

function getTypeSlug(type: string): string {
	switch (type) {
		case "event":
			return "/program";
		case "artist":
			return "/program/artists";
		case "infoPage":
			return "information";
		case "venue":
			return "/program/venues";
		default:
			return "/program/events";
	}
}
