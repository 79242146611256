import type React from 'react';
import kebabCase from 'lodash/kebabCase';

export interface Anchors {
  anchor: string,
  text: string
}

/**
 * Generate an id for use in #links from a given heading
 * @param heading
 */
export function idFromHeading(heading: string | React.ReactNode): string | undefined {
  if (typeof heading === 'string') {
    return kebabCase(
      heading.replaceAll(/![A-Za-z0-9]/g, ''),
    );
  }

  if (!heading) {
    return undefined;
  }

  /**
   * TODO: what really happens when heading isn't a string? (i.e. if it contains nested anchor link)
   * hint: nothing good probably
   */
  return idFromHeading(heading.toString());
}
