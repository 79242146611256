import classNames from 'classnames';
import React from 'react';
import styles from '../PortableText.module.scss';

interface ListItemProps {
  children?: React.ReactNode;
  className?: string;
}

export default function ListItem({
  children,
  className,
}: ListItemProps) {
  if (!children) {
    return null;
  }

  return (
    <li className={classNames(styles.listItem, className)}>
      {children}
    </li>
  );
}
