import { ProgramPhase } from "@/sanity/schemas/vendored/enums";
import classNames from "classnames";
import React from "react";
import styles from "../PortableText.module.scss";

interface PhaseSpecificCopyProps {
	children?: React.ReactNode;
	className?: string;
	phase?: ProgramPhase;
	currentPhase: ProgramPhase;
	onlyThisPhase?: boolean;
}

/**
 * Inject
 * @param props
 * @returns
 */
export function PhaseSpecificCopy({
	children,
	className,
	phase,
	currentPhase,
	onlyThisPhase = true,
}: PhaseSpecificCopyProps) {
	if (!children || !phase) {
		return null;
	}

	if (onlyThisPhase && phase >= currentPhase) {
		return (
			<span className={classNames(styles.phaseSpecificCopy, className)}>
				{children}
			</span>
		);
	}

	if (!onlyThisPhase && phase <= currentPhase) {
		return (
			<span className={classNames(styles.phaseSpecificCopy, className)}>
				{children}
			</span>
		);
	}

	return null;
}
