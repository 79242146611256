import Image from "next/image";

import { nextImageMediaSize } from "@/lib/helpers/imageSizes";
import { imageUrlWithSize } from "@/lib/sanity/helpers/image";
import type { PortableTextTypeComponentProps } from "@portabletext/react";
import classNames from "classnames";
import styles from "../PortableText.module.scss";

export interface BlockImageProps extends PortableTextTypeComponentProps<any> {
	className?: string;
}

/**
 * Inject
 * @param props
 * @returns
 */
export default function BlockImage({ value, className }: BlockImageProps) {
	if (!value) {
		return null;
	}

	const { image, alt } = value;

	const imageUrl = imageUrlWithSize(image.asset._ref, 1000, 1000);

	const asset = {
		url: imageUrl,
		alt,
	};

	return (
		<div className={classNames(styles.blockImage, className)}>
			<Image
				className={styles.blockImageAsset}
				src={asset.url}
				alt={asset.alt}
				{...nextImageMediaSize("listing")}
			/>
		</div>
	);
}
